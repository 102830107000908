body {
  font-family: "Quattrocento Sans", sans-serif !important;
  background: #fff;
  color: #444;
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
}

.App {
  text-align: center;
  }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homepage-bg {
  position: absolute !important;
  top: 0 !important;
  bottom: 81px !important;
  left: 0 !important;
  overflow: hidden !important;
  z-index: 0 !important;
  width: 100% !important; 
}
  .homepage-bg:after {
    position: absolute !important;
    z-index: -1 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    -webkit-transform: translate3d(40px, 0, 0);
    -moz-transform: translate3d(40px, 0, 0);
    -ms-transform: translate3d(40px, 0, 0);
    -o-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
    -webkit-transition-duration: 1s, 1s;
    -moz-transition-duration: 1s, 1s;
    transition-duration: 1s, 1s;
    -webkit-transition-timing-function: ease, ease;
    -moz-transition-timing-function: ease, ease;
    transition-timing-function: ease, ease;
    background: transparent url("/images/jon_tee_bg_cut_small.webp") 100% 100% no-repeat;
    content: '';
    opacity: 0;
  }

  .backgroundReady.homepage-bg:after {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 0.8; }


.logoLine span:before, .logoLine span:after {
  position: absolute;
  top: 50%;
  height: 80px;
  width: 60px;
  content: '';
  margin-top: -40px; }
.logoLine span:before {
  background: no-repeat url("/images/triangle-left.png") 100% 50%;
  left: -40px;
  }
.logoLine span:after {
  background: no-repeat url("/images/triangle-right.png") 0 50%;
  right: -40px; 
}


.aboutme
.aboutme-ribbon {
  margin: 30px auto 58px; }


  .ribbon {
    font: normal 600 30px/48px "Lora", serif;
    color: #000;
    margin: 0 auto;
    max-width: 775px;
    padding: 0;
    text-align: center;
    position: relative; }
    .ribbon:before {
      display: block;
      border-bottom: 1px solid #eeecea;
      content: '';
      height: 0px;
      width: 100%;
      position: absolute;
      bottom: 8px;
      left: 0px;
      z-index: 3; }
    .ribbon span {
      display: block;
      height: 18px;
      width: 473px;
      position: relative;
      margin: 9px auto 0;
      background: #eeecea; 
    }
      .ribbon span:before, .ribbon span:after {
        position: absolute;
        top: 50%;
        height: 80px;
        width: 60px;
        content: '';
        margin-top: -40px;
        z-index: 2; }
      .ribbon span:before {
        background: no-repeat url("/images/triangle-left.png") 100% 50%;
        left: -38px; 
      }
      .ribbon span:after {
        background: no-repeat url("/images/triangle-right.png") 0 50%;
        right: -38px; 
      }

      #root > .container .regularPageContainer .ribbon .header  {
        text-transform: uppercase;
      }

      #root > .container .regularPageContainer .ribbon .tinyHeading  {
        padding: 0 !important;
        margin: 0 !important;
      }
  
  .ribbon-help {
    margin: 0 100px 55px;
    padding: 0;
    text-align: center;
    font-size: 14px;
    line-height: 26px; 
  }
  



#root > .container .regularPageContainer {
  max-width: 1400px !important;
  width: inherit !important;
}

#root > .container .regularPageContainer > .tinyPageContainer {
  Width: 95% !important;
}

#root > .container .regularPageContainer > .smallPageContainer {
  Width: 85% !important;
}

#root > .container .regularPageContainer > .mediumPageContainer {
  Width: 85% !important;
}

#root > .container .regularPageContainer h3 {
  line-height: 1.2;
  font-family: 'Trebuchet MS', Arial, sans-serif;
  color: #333;
  font-weight: bold;
}

#root > .container .regularPageContainer p {
  font-family: "Quattrocento Sans", sans-serif;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
  text-align: justify;
  padding-bottom: 15px;
  color: #333;
}

#root > .container .regularPageContainer .tinyFluidContainer, #root > .container .regularPageContainer .tinyFluidContainer .container {
  margin: 0 !important;
  padding: 0 !important;
}

#root > .container .regularPageContainer .tinyFluidContainer .jobTimeLineTiny {
  margin-bottom: 20px !important;
}

.menuHeader {
  z-index: 1;
}

.fixed.menuHeader {
  position: fixed;
  padding: 1em;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}


.footer-social-links {
  text-align: right;
  float: right;
  position: relative;
  top: -10px;
  padding-right: 10%;
} 

.footer-social-links a {
  height: 36px;
  width: 36px;
  color: #6d6963;
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
  font-size: 16px;
  margin-right: 5px;
  transition: all 0.25s ease;
  text-align: center;
  text-decoration: none;
}

.button {
  background: rgb(200, 189, 174);
  border: 1px solid rgb(200, 189, 174);
  margin: 0px 0px 68px;
  transition: background-color 0.25s ease 0s, color 0.25s ease 0s;
  border-radius: 2px;
  color: rgb(255, 255, 255);
  vertical-align: top;
  font: 400 18px / 39px "Quattrocento Sans", sans-serif;
  text-align: center;
  height: 38px;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  letter-spacing: 2px;
  width: 325px;
  padding: 0px;
}

.button:hover, .barsMenu a:hover  {
  background-color: #fff !important;
  color: #c8bdae !important;
  text-decoration: none !important; }
.button[disabled], .button[disabled]:hover {
  background: #f1efeb !important;
  border: 1px solid #f1efeb !important;
  color: #c8bdae !important; }

  .svgNavItem {
    color: #c8bdae; 
  }
  
  .svgNavItem a:hover {
    color: #c8bdae !important; 
  }

  .svgNavItem a:hover svg {
    -webkit-transform: scale(1.2) translate(0, -50%) !important;
    -moz-transform: scale(1.2) translate(0, -50%) !important;
    -ms-transform: scale(1.2) translate(0, -50%) !important;
    -o-transform: scale(1.2) translate(0, -50%) !important;
    transform: scale(1.2) translate(0, -50%) !important;
  }

  .ui.text.container.textParagraph {
    max-width: inherit !important;
  }

  div.ui.container.homePageHeading {
    max-width: 500px !important;
    display: inline-block;
    text-align: center;
  }

  div.ui.container > header > .barsMenuSmall {
    margin-right: 0px !important;    
  }

  div.ui.container > header > .barsMenuSmall .barsMenu {
    margin-right: 0px !important;    
  }
  
  .barsMenu i.bars {
    position: relative;
    top: -35px;
    left: -8px;
  }

  .mobileMenu {
    left: -50px !important;
  }

  .currentNavPage {
    border-bottom: 7px solid #c8bdae !important;
    color: #c8bdae !important;
    transition: border-bottom 1s !important;
    -webkit-transition: all 200ms ease-in-out !important;
    -moz-transition: all 200ms ease-in-out !important;
    -o-transition: all 200ms ease-in-out !important;
    -ms-transition: all 200ms ease-in-out !important;
    transition: all 200ms ease-in-out !important;
  }

  .smallGrid .column {
    padding: 0 !important;
  }

  /* .imageGallery .ui.horizontal.list:not(.celled)>.item:first-child .image,   .imageGallery .ui.horizontal.list>.item .image {
    margin: 50px !important;
  } */

  @keyframes fadeInOpacity1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInOpacity2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes partialFadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.6;
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .imageGallery {      
    animation-name: slideInFromRight;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 1250ms;
  }

  .imageGallery .toggle1 {  
    opacity: 1;
    animation-name: fadeInOpacity1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 500ms;
  }

  .imageGallery .toggle2 {  
    opacity: 1;
    animation-name: fadeInOpacity2;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 500ms;
  }

  .imageGallery .image {
    margin: 15px !important;
  }

  .imageGallery .image:hover {
    cursor: pointer; 
  }

  .imageGallerySingle .toggle1 {  
    opacity: 1;
    animation-name: fadeInOpacity1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 250ms;
  }

  .imageGallerySingle .toggle2 {  
    opacity: 1;
    animation-name: fadeInOpacity2;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 250ms;
  }

  .showAllGallery .toggle1 {  
    opacity: 0.6;
    animation-name: partialFadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 250ms;
  }

  .showAllGallery .toggle2 {  
    opacity: 0.6;
    animation-name: partialFadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 250ms;
  }

  .showAllGallery .image:hover {
    opacity: 1;
  }

.contactsForm .field input, .contactsForm .field textarea {
    background: #f7f5f3 !important;
    border-color: #f7f5f3 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    color: rgb(137, 128, 117) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    resize: none !important;
    height: 50px !important;
    line-height: initial !important;
}

.contactsForm .field textarea {
  height: inherit !important;
}

.contactsForm .field input:focus, .contactsForm .field textarea:focus {
  border-color: #f7f5f3 !important;
  background: #fff !important; 
}

.tinyButton {
  width: 100% !important;
}


.jobTimeLine .company:before, .jobTimeLine .other:before {
  position: absolute;
    top: 207%;
    height: 27px;
    width: 112px;
    content: '';
    margin-top: -40px;
    background: no-repeat url(/images/triangle-left.png) 100% 50%;
    left: -94px;
}

.jobTimeLine .date:after {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  border: 2px solid #eeecea;
  background: #fff;
  content: '';
  position: relative;
  right: -21px;
  float: right;
  z-index: 1;
  margin-top: 5px;
}

.cv ol li {
  line-height: 26px;
}

.buttons .button.navButton {
  background-color: #eeecea !important;
  color: black !important;
  border: 1px solid #c8bdae;
}

.buttons .button.navButton:hover {
  background-color: #c8bdae !important;
  color: '#c8bdae' !important;
}